<template>
  <oiliness-location-template
    :gender="gender"
    :greasy-skin-locations="greasySkinLocations"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import OilinessLocationTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-oiliness/oiliness-location/OilinessLocationTemplate';

const { field, requiredField } = fieldBuilder;

const FIELDS = [field('gender'), requiredField('greasySkinLocations')];

export default {
  name: 'OilinessLocation',
  components: {
    OilinessLocationTemplate
  },
  mixins: [makeStep(FIELDS)],
  watch: {
    greasySkinLocations() {
      this.showNextStep();
    }
  }
};
</script>
